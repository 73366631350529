<template>
  <div class="home news-bj">
    <div class="news-HImg">
      <img src="@/assets/images/1551929445.jpg" />
    </div>
    <div class="news-HCont">
      <nav class="navbar navbar-expand-lg">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link class="nav-link" to="/news">全部</router-link>
          </li>
          <li
            class="nav-item"
            v-for="(newsType, index) in websiteNewsType"
            :key="index"
          >
            <router-link
              class="nav-link"
              :to="{ path: '/news?news_type=' + newsType.dictValue }"
              >{{ newsType.dictLabel }}</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="news-cont container">
      <div class="news-CL col-md-9">
        <ul class="cnews-list" v-if="newsList.length>0">
          <li v-for="(news, index) in newsList" :key="index">
            <h4>
              <router-link
                target="_blank"
                :to="{ path: 'newsdetails', query: { id: news.newsId } }"
                >{{ news.newsTitle }}</router-link
              >
            </h4>
            <p class="desc">
              {{ news.newsContent | filtersText }}
            </p>
            <p class="info">
              <span>{{ news.createTime | filtersData }}</span>
              <b-icon icon="eye-fill" width="18" height="18"></b-icon>
              <span>{{ news.hits }}</span>
            </p>
          </li>
        </ul>
        <div class="noData" v-else>
          暂无数据
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-if="currentPage > 1" @click="prevPage()">
              <span class="page-link">上一页</span>
            </li>
            <li class="page-item disabled" v-else>
              <span class="page-link">上一页</span>
            </li>
            <li
              class="page-item"
              v-for="index of totalPage"
              :key="index"
              :class="{ active: currentPage == index }"
              @click="pageClick(index)"
            >
              <span class="page-link">{{ index }}</span>
            </li>
            <li class="page-item disabled" v-if="currentPage >= totalPage">
              <span class="page-link" aria-label="Next">下一页</span>
            </li>
            <li class="page-item" v-else @click="nextPage()">
              <span class="page-link" aria-label="Next">下一页</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="news-CR col-md-3">
        <!--<div class="form-group">
          <div class="input-search">
            <button type="submit" class="input-search-btn butbg">
              <b-icon icon="search" width="18" height="18"></b-icon>
            </button>
            <input
              type="text"
              class="form-control"
              name="searchword"
              placeholder="search"
            />
          </div>
        </div>-->
        <div class="left-menu">
          <ul class="list-group left-news-menu">
            <li class="list-group-item">
              <router-link class="active" to="/news">新闻中心</router-link>
            </li>
            <li
              class="list-group-item"
              v-for="(newsType, index) in websiteNewsType"
              :key="index"
            >
              <router-link
                :to="{ path: '/news?news_type=' + newsType.dictValue }"
                >{{ newsType.dictLabel }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="recommend">
          <h5>为你推荐</h5>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(news, index) in recommendNews"
              :key="index"
            >
              <router-link
                :to="{ path: 'newsdetails', query: { id: news.newsId } }"
                >{{ news.newsTitle }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "News",
  data() {
    return {
      //网站新闻数据
      newsList: [],
      //路由中网站的新闻类型
      newsType: null,
      //为你推荐新闻
      recommendNews: null,
      //网站新闻类型
      websiteNewsType: null,
      //当前路由地址
      fullPath: null,
      //每页显示10条数据
      pageSize: 10,
      //当前页
      currentPage: 1,
      //总页数
      totalPage: 1,
      //新闻总条数
      totalNum: 0,
    };
  },
  mounted() {
    //获取当前路由地址
    this.fullPath = this.$route.fullPath;
    var _this = this;
    //获取新闻类型
    _this.newsType = this.$route.query.news_type;
    //获取新闻信息
    _this.getNews();
  },
  methods: {
    getNews() {
      document.querySelector("#app").scrollIntoView(true);
      var _this = this;
      var params = {};
      params["pageNum"] = _this.currentPage;
      params["pageSize"] = _this.pageSize;
      if (_this.newsType != undefined) {
        params["newsType"] = _this.newsType;
      }
      //ajax获取新闻列表信息
      _this.$axios
        .get("/system/websitenews/websiteList", { params: params })
        .then(function (response) {
          if (response.data.code == 200) {
            _this.newsList = response.data.data.news.rows;
            _this.totalNum = response.data.data.news.total;
            _this.recommendNews = response.data.data.recommend;
            _this.websiteNewsType = response.data.data.news_type;
          }
        });
    },
    newsMenuChoose() {
      var _this = this;
      //菜单选中
      var fullPath = "#" + _this.fullPath;
      $(".news-HCont").find(".navbar-nav").find("li").removeClass("active");
      $(".news-HCont")
        .find(".navbar-nav")
        .find("a")
        .each(function (index, element) {
          var path = $(element).attr("href");
          if (path == fullPath) {
            $(element).parent("li").addClass("active");
          }
        });
      //左边菜单选中
      $(".left-news-menu").find("a").removeClass("active");
      $(".left-news-menu")
        .find("a")
        .each(function (index, element) {
          var path = $(element).attr("href");
          if (path == fullPath) {
            $(element).addClass("active");
          }
        });
    },
    getPage() {
      var _this = this;
      //分页
      _this.totalPage = Math.ceil(_this.totalNum / _this.pageSize);
    },
    //分页
    pageClick(index) {
      this.currentPage = index;
      this.getNews();
    },
    //上一页
    prevPage() {
      this.currentPage = this.currentPage - 1;
      this.getNews();
    },
    //下一页
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.getNews();
    },
  },
  updated: function () {
    this.$nextTick(function () {
      var _this = this;
      //新闻菜单选中
      _this.newsMenuChoose();
      _this.getPage();
    });
  },
  watch: {
    //监听路由改变
    $route(to) {
      var _this = this;
      //获取当前路由地址
      _this.fullPath = to.fullPath;
      //获取新闻类型
      if (_this.newsType != to.query.news_type) {
        _this.currentPage = 1;
      }
      _this.newsType = to.query.news_type;
      //获取新闻信息
      _this.getNews();
      //新闻菜单选中
      _this.newsMenuChoose();
    },
  },
  filters: {
    //提纯富文本中的文字并显示前150的字符
    filtersText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1, "");
        return msg.substr(0, 150);
      } else {
        return "";
      }
    },
    filtersData(val) {
      if (val != null && val != "") {
        return val.substr(0, 10);
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.news-bj {
  background-color: #f2f2f2 !important;
}
.news-HImg img {
  width: 100%;
  height: 200px;
}
.news-HCont {
  width: 100%;
  display: block;
  background-color: #ffffff;
}
.news-HCont .navbar-nav .active > .nav-link {
  color: #00c9a1 !important;
}
.news-HCont .navbar {
  border-bottom: 1px solid #e5e7ea;
}
.news-HCont .navbar-nav {
  margin: 0px auto;
  padding: 15px 0px;
}
.news-HCont .navbar-nav a {
  color: #555555 !important;
  font-size: 16px;
}
.navbar-nav .dropdown-item:hover a {
  color: #00c9a1 !important;
}
.news-HCont .dropdown-menu {
  background-color: #ffffff !important;
  line-height: 30px;
}

.news-cont {
  display: flex;
  padding: 30px 0px;
}
.news-CL,
.news-CR {
  background-color: #ffffff;
  padding: 15px 0px;
}
.news-CR {
  padding: 20px;
  margin: 0px 0px 0px 20px;
}
.cnews-list {
  padding: 25px 25px 0px 25px;
}
.cnews-list li {
  border-bottom: 1px solid #e5e7ea;
  margin: 20px 0px;
}
.cnews-list li a {
  color: #555555;
}
.cnews-list li a:hover {
  text-decoration: none;
  color: #00c9a1;
}
.cnews-list li .desc {
  color: #999999;
  font-size: 16px;
  line-height: 25px;
}
.cnews-list li .info span,
.cnews-list li .info svg {
  color: #999999;
  margin-right: 10px;
}

.pagination li {
  cursor: pointer;
}
.pagination li a {
  color: #76838f;
}
.pagination .disabled .page-link {
  color: #ccd5db;
}
.page-item.active .page-link {
  background-color: #00c9a1;
  border-color: #00c9a1;
  color: #ffffff;
}

.input-search .input-search-btn {
  position: absolute;
  top: 25px;
  right: 30px;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.input-search .form-control {
  border-radius: 2rem;
}
.input-search .form-control:focus {
  border: 1px solid#00c9a1;
  box-shadow: none;
}
.recommend {
  border-top: 1px solid #f0f2f5;
  margin: 20px 0px;
  padding: 20px 0px 0px 0px;
}
.left-menu .list-group,
.left-menu .list-group .list-group-item {
  border: none;
  padding: 5px 0px;
  font-size: 16px;
}
.left-menu .list-group .list-group-item .active {
  color: #00c9a1;
}
.recommend h5 {
  color: #555555;
  font-weight: 300;
}
.recommend a,
.left-menu a {
  color: #555555;
}
.recommend a:hover,
.left-menu a:hover {
  text-decoration: none;
  color: #00c9a1;
}

.noData{
  padding: 25px;
  text-align: center;
  color: #ccd5db;
  font-size: 16px;
}
</style>
